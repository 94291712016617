import * as Sentry from "@sentry/remix";
import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import { startTransition, StrictMode, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";

Sentry.init({
  dsn: "https://a229596ea44578330f3c9866e1866999@o4507562606002176.ingest.us.sentry.io/4507562618716160",
  tracesSampleRate: 1,
  replaysSessionSampleRate: 0.05,
  replaysOnErrorSampleRate: 0.1,
  environment: process.env.NODE_ENV,
  enabled: process.env.NODE_ENV === "production",

  integrations: [Sentry.browserTracingIntegration({
    useEffect,
    useLocation,
    useMatches
  }),
    // eslint-disable-next-line import/namespace
    Sentry.replayIntegration()
  ]
});

function App() {
  return <RemixBrowser />;
}

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <App />
    </StrictMode>
  );
});
